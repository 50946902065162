"use client";

import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { S3_BASE_URL } from "@/utils/constants";
import CartModal from "../popUpSection/cartModel";
import { useCart } from "@/context/CartContext";
import {
  addProductToCart,
  createCart,
  fetchCart,
  updateCartLineQuantity,
} from "@/utils/Shopify/products";
import { CardSlider, PageContent } from "@/types/page-content";
import { bannerProductId, bannerVariantId, setProductDetails } from "@/utils/baseEnvConstant";
import { setHrefValue } from "@/utils/productConstants";

interface HomeProps {
  pages: PageContent;
}
interface ImageProps {
  src: string;
  alt: string;
}
interface CardSliderProps {
  item: CardSlider;

}

const ImageZoom = ({ src, alt }: ImageProps) => {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting); // Set visibility based on intersection
        });
      },
      { threshold: 0.1 } // Adjust the threshold to control when it triggers
    );

    if (imgRef.current) {
      observer.observe(imgRef.current); // Observe the image element
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current); // Cleanup observer on unmount
      }
    };
  }, []);

  return (
    <div className={`hpw-img ${isVisible ? "zoom-in" : ""}`} ref={imgRef}>
      <Image
        src={src}
        alt={alt}
        width={0} // Adjust as necessary
        height={0} // Adjust as necessary
        sizes="100vw"
        style={{ width: "100%", height: "auto" }}
        className="img-fluid"
      />
    </div>
  );
};

const FadeUpContent = ({
  item,
  handleAddToCart,
  handleClick,
  handleCartShow,
  handleCartClose,
}: any) => {
  let hrefValue: any = "#";
  const contentRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  setProductDetails(item.product?.slug,item)
  hrefValue=setHrefValue(item.product.slug);

 
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      { threshold: 0.1 }
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <>
      <div
        className={`hpw-content ${isVisible ? "fade-in" : ""}`}
        ref={contentRef}
      >
        <h1 className="prod-title">{item?.product?.name || ""}</h1>
        <h2 className="prod-sub-title">{item?.title || ""}</h2>
        <h6 className="prod-tag-line">{item?.sub_title || ""}</h6>
        <div className="btn-actions d-flex justify-content-sm-start justify-content-center align-items-center  d-none d-sm-block">
          <Link
            href="#"
            className="btn btn-blue me-3 zoom-out"
            onClick={() => {
              handleCartShow();
              handleAddToCart(item,item.productId, item.variantIds[0]);
            }}
          >
            Buy
          </Link>
          <Link href={hrefValue} onClick ={() => {
                                  handleClick(item)}
                                } className="btn btn-outline fade-in-left"           >
            Learn More
          </Link>
        </div>
        <div className="prod-desc  d-none d-sm-block">
          <div
            dangerouslySetInnerHTML={{
              __html: item?.description || "",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

const FadeUpContentMobile = ({
  item,
  handleAddToCart,
  handleClick,
  handleCartShow,
  handleCartClose,
}: any) => {
  let hrefValue: any = "#";
  const contentRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  setProductDetails(item.product?.slug,item)
  hrefValue=setHrefValue(item.product.slug);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      { threshold: 0.1 }
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <>
      <div
        className={`hpw-content ${isVisible ? "fade-in" : ""}`}
        ref={contentRef}
      >
        <div className="btn-actions d-flex justify-content-sm-start justify-content-center align-items-center">
          <Link
            href="#"
            className="btn btn-blue me-3 zoom-out"
            onClick={() => {
              handleCartShow();
              handleAddToCart(item,item.productId, item.variantIds[0]);
            }}
          >
            Buy
          </Link>
          <Link href={hrefValue} onClick ={() => {
                                  handleClick(item)}
                                } className="btn btn-outline fade-in-left" 
                                >
            Learn More
          </Link>
        </div>
        <div className="prod-desc">
          <div
            dangerouslySetInnerHTML={{
              __html: item?.description || "",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default function HomeSection({ pages }: HomeProps) {

// Code Changed due to Client Side Rendering(CSR)

  useEffect(() => {
    const CURRENT_STORE_DOMAIN = 'dev-store.alethahealth.com'; 
    const savedDomain = localStorage.getItem('storeDomain'); // Check if the saved domain is different from the current domain
     if (savedDomain && savedDomain !== CURRENT_STORE_DOMAIN) 
    { 
    // Clear cart-related local storage items 
    localStorage.removeItem('cartCheckoutData'); 
    localStorage.setItem('storeDomain', CURRENT_STORE_DOMAIN); } 
    else if (!savedDomain) {
     localStorage.setItem('storeDomain', CURRENT_STORE_DOMAIN);
     }
  }, []);

  const banner = pages.banners[0];
  const [cartShowModel, setCartShowModel] = useState<boolean>(false);

  const { itemCount, setItemCount } = useCart();
  const { cart, setCart } = useCart();

  const handleCartShow = () => {
    setCartShowModel(true);
  };

  const handleCartClose = () => {
    setCartShowModel(false);
  };


  const handleAddToCart = async (item: any,productId: string, variantId?: string) => {
    // if (typeof window.fbq === 'function') {
    //   window.fbq('track', 'AddToCart');
    // }
    // window.dataLayer?.push({
    //   event: 'addToCart',
    //   CustomerId :'user',
    //   ecommerce: {
    //     currencyCode:'USD',
    //     add:{
    //       products:[
    //         {
    //            productId: productId,
    //            variantId: variantId,
    //            name: item?.product?.name||item.title,
    //            image: item?.product?.desktop_image_url||item.desktop_image_url,
    //            price: item?.product?.price??0,
    //         }
    //       ]
    //     }
    //   },
    // });
    let storedData = localStorage.getItem("cartCheckoutData");
    let cartId = storedData ? JSON.parse(storedData).cartId : null;

    if (cartId) {
      // Fetch the existing cart data
      const cartData = await fetchCart(cartId);

      // Check if the product already exists in the cart
      let existingLineItem;
      if (variantId) {
        // If there's a variant, check if the variant already exists in the cart
        existingLineItem = cartData.lines.edges.find(
          (edge: any) => edge.node.merchandise.id === variantId
        );
      } else {
        // If no variant, find the product by productId
        existingLineItem = cartData.lines.edges.find(
          (edge: any) => edge.node.merchandise.product.id === productId
        );
      }

      let updatedCart;
      if (existingLineItem) {
        // If the product (variant or simple product) already exists in the cart, update the quantity
        updatedCart = await updateCartLineQuantity(
          cartId,
          existingLineItem.node.id,
          existingLineItem.node.quantity + 1
        );
        setCart(updatedCart);
      } else {
        // If the product (variant or simple product) is new, add it to the cart
        if (variantId) {
          // If there's a variant, add the product with variant
          updatedCart = await addProductToCart(cartId, variantId, 1);
        } else {
          // If it's a simple product, add it by productId
          updatedCart = await addProductToCart(cartId, productId, 1);
        }
        setCart(updatedCart);
      }

      // Recalculate and update the total item count based on the updated cart
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);

      // Update local storage with current cartId and checkoutId (if any)
      storedData = localStorage.getItem("cartCheckoutData");
      const { checkoutId } = storedData
        ? JSON.parse(storedData)
        : { checkoutId: null };
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId, checkoutId })
      );
    } else {
      // If no cart ID exists, create a new cart
      const newCartId = await createCart();
      const newCheckoutId = null;
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId: newCartId, checkoutId: newCheckoutId })
      );

      // Fetch the newly created cart
      const cartData = await fetchCart(newCartId);
      setCart(cartData);

      // Add the product to the newly created cart
      let updatedCart;
      if (variantId) {
        // If there's a variant, add the product with variant
        updatedCart = await addProductToCart(newCartId, variantId, 1);
      } else {
        // If it's a simple product, add it by productId
        updatedCart = await addProductToCart(newCartId, productId, 1);
      }
      setCart(updatedCart);

      // Recalculate and update the item count
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);
    }
  };
  const handleClick = (item: any) => {
    if (typeof window.fbq === 'function') {
      window.fbq('track', 'productDetailView', );
    }
    window.dataLayer?.push({
      event: 'productDetailView',
      CustomerId :'user',
      ecommerce: {
        currencyCode:'USD', 
        detail:{
        products: [
          {            
          id:item?.product?.shopify_product_id,
          name: item?.product?.name || item.title,
          image: item?.product?.desktop_image_url||item.desktop_image_url,
          productId: item?.product?.shopify_product_id,
          variantId: item?.product?.shopify_variant_id,
          price: item?.product?.price,
          },
        ],
      },
    }
    });
  };

  return (
    <>
      <CartModal cartShow={cartShowModel} handleClose={handleCartClose} />
      <div className="content-wrap">
        <div className="container g-sm g-0 d-sm-block d-none">
          <div
            className="banner-wrap home-banner-wrap d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: `url(${S3_BASE_URL}${banner?.desktop_image_url})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right center",
              // backgroundSize: "120% auto", 
             
              backgroundSize: "contain",
              backgroundColor: "rgba(21, 21, 21, 0.93)",
              backgroundAttachment: "fixed",
              backgroundClip: "border-box",
              width: "100%",                       // Container width
              height: "692px", 
              // height: "100%",
              //  width: "1320px",        // Set the width as specified
              // height: "692px",
            }}
          >
            
            <div className="banner-prod-info">
              <Image
                src="/assets/images/v2/blackFridayText.png"
                alt=""
                width="300"
                height="400"
                className="d-md-block d-none rotate-animation ms-4"
                style={{
                  width: '877px',       
                  height: '301px',      
                                 }}
              />
              <div className="d-flex justify-content-center">
                <Image
                  src="/assets/images/v2/blackFridayText.png"
                  alt=""
                  width="200"
                  height="300"
                  className="d-sm-block d-md-none"
                  style={{
                    width: '200px',       
                    height: '301px',      
                                   }}
                />
              </div>

              <div className="btn-actions d-flex justify-content-center align-items-center">
                <Link
                  href="#"
                  className="btn btn-blue me-3 zoom-out"
                  onClick={() => {
                    setCartShowModel(true);
                    handleAddToCart(banner,bannerProductId, bannerVariantId[0]);
                  }}
                >
                  Buy
                </Link>
                <Link href="/set" className="btn btn-outline fade-in-left"  onClick ={() => {
                                  handleClick(banner)}
                                }>
                  Learn More
                </Link>
              </div>
              <div className="bpi-text text-center">{banner?.title}</div>
              <div className="bpi-text text-center">
                <div
                  dangerouslySetInnerHTML={{
                    __html: banner?.description || "",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="home-prod-wrap d-md-block d-none">
            {pages.card_slider.map((item: CardSlider, index: number) => (
              <div className="row" key={index}>
                <div
                  className={`col-12 col-sm-12 col-md-5 d-flex align-items-center ${
                    index % 2 === 0 ? "order-2" : "order-1"
                  }`}
                >
                  <FadeUpContent
                    item={item}
                    handleAddToCart={handleAddToCart}
                    handleClick={handleClick}
                   
                    handleCartShow={handleCartShow}
                    handleCartClose={handleCartClose}
                  />
                </div>
                <div
                  className={`col-12 col-sm-12 col-md-7 ${
                    index % 2 === 0 ? "order-1" : "order-2"
                  }`}
                >
                  <ImageZoom
                    src={`${S3_BASE_URL}${item?.desktop_image_url}`}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="home-prod-wrap d-sm-block d-md-none">
            {pages.card_slider.map((item: CardSlider, index: number) => (
              <div className="row mb-5" key={index}>
                <div className="col-12 col-md-7 text-center">
                  <FadeUpContent
                    item={item}
                    handleAddToCart={handleAddToCart}
                    handleClick={handleClick}
                    handleCartShow={handleCartShow}
                    handleCartClose={handleCartClose}
                  />
                  <ImageZoom
                    src={`${S3_BASE_URL}${item?.desktop_image_url}`}
                    alt=""
                  />
                </div>
                <div className="col-12 col-md-4 text-center">
                  <div className="hpw-content d-md-block d-none">
                    <div className="btn-actions d-flex justify-content-sm-start justify-content-center align-items-center">
                      <Link href="#" className="btn btn-blue me-3">
                        Buy
                      </Link>
                      <Link href="#" className="btn btn-outline" onClick ={() => {
                                  handleClick(item)}
                                }>
                        Learn More
                      </Link>
                    </div>
                    <div className="prod-desc">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description || "",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container g-sm g-0 d-sm-none d-block">
          <div
            className="banner-wrap home-banner-wrap d-flex justify-content-center align-items-center"
            style={{
              background: `url(${S3_BASE_URL}${banner?.mobile_image_url})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 20%",
              backgroundSize: "contain",
              backgroundColor: "rgba(21, 21, 21, 0.93)",
              // backgroundAttachment: "fixed",
              backgroundClip: "border-box",
              height: "100%",
            }}
          >
            <div className="banner-prod-info">
              <Image
                src="/assets/images/v2/blackFridayText.png"
                alt=""
                width="320"
                height="155"
                className="d-md-block d-none rotate-animation"
               />
              <div className="d-flex justify-content-center">
                <Image
                  src="/assets/images/v2/blackFridayText.png"
                  alt=""
                  width="320"
                  height="155"
                  className="d-sm-block d-md-none"            
                />
              </div>

              <div className="btn-actions d-flex justify-content-center align-items-center">
                <Link
                  href="#"
                  className="btn btn-blue me-3 zoom-out"
                  onClick={() => {
                    setCartShowModel(true);
                    handleAddToCart(banner,bannerProductId, bannerVariantId[0]);
                  }}
                >
                  Buy
                </Link>
                <Link href="/set" className="btn btn-outline fade-in-left"  onClick ={() => {
                                  handleClick(banner)}
                                }>
                  Learn More
                </Link>
              </div>
              <div className="bpi-text text-center">{banner?.title}</div>
              <div className="bpi-text text-center">
                <div
                  dangerouslySetInnerHTML={{
                    __html: banner?.description || "",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="home-prod-wrap d-md-block d-none">
            {pages.card_slider.map((item: CardSlider, index: number) => (
              <div className="row" key={index}>
                <div
                  className={`col-12 col-sm-12 col-md-5 d-flex align-items-center ${
                    index % 2 === 0 ? "order-2" : "order-1"
                  }`}
                >
                  <FadeUpContent
                    item={item}
                    handleAddToCart={handleAddToCart}
                    handleClick={handleClick}                 
                    handleCartShow={handleCartShow}
                    handleCartClose={handleCartClose}
                  />
                </div>
                <div
                  className={`col-12 col-sm-12 col-md-7 ${
                    index % 2 === 0 ? "order-1" : "order-2"
                  }`}
                >
                  <ImageZoom
                    src={`${S3_BASE_URL}${item?.mobile_image_url}`}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="home-prod-wrap d-sm-block d-md-none">
            {pages.card_slider.map((item: CardSlider, index: number) => (
              <div className="row mb-5" key={index}>
                <div className="col-12 col-md-7 text-center">
                  <FadeUpContent
                    item={item}
                    handleAddToCart={handleAddToCart}
                  
                    handleCartShow={handleCartShow}
                    handleCartClose={handleCartClose}
                  />
                  <ImageZoom
                    src={`${S3_BASE_URL}${item?.mobile_image_url}`}
                    alt=""
                  />
                  <FadeUpContentMobile
                    item={item}
                    handleAddToCart={handleAddToCart}

                    handleCartShow={handleCartShow}
                    handleCartClose={handleCartClose}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

