"use client";

import { useState } from "react";

export default function AdvertisementSection() {
  const [showTopLabel, setShowTopLabel] = useState(true);

  return (
    <>
      <div style={{ padding: "12px 70px", justifyContent: "space-between" }}>
        <div
          className={`top-label ${
            showTopLabel ? "d-block" : "d-none"
          } d-md-block ${showTopLabel ? "" : "d-md-none"}`}
        >
          <p
            className="text-center"
            style={{ fontSize: "20px", lineHeight: "27px", marginBottom: "0" 
            }}
          >
            <a href="/set" style={{ textDecoration: "none",color: "#0C1218" }}>
              {" "}
              Limited Edition Black Set. Lock in Black Friday pricing 
              <span style={{ textDecoration: "underline" ,color: "#0C1218"}}>
                {" "}
                now
              </span>
              .
            </a>
          </p>
          <span
            className="position-absolute top-0 end-0 me-3 mt-2 float-end"
            style={{
              cursor: "pointer",
              zIndex: 1000,
              top: "0",
              right: "0",
            }}
            onClick={() => setShowTopLabel(false)}
          >
            x
          </span>
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 992px) {
          .top-label {
            padding-right: 20px;
          }
          .position-absolute {
            top: 10px;
            right: 10px;
          }
        }

        @media (max-width: 480px) {
          .top-label {
            padding-right: 12px;
          }
          .position-absolute {
            top: 15px;
            right: 15px;
          }
        }
        @media (max-width: 768px) {
          .top-label {
            padding-right: 25px;
          }
          .position-absolute {
            top: 12px;
            right: 12px;
          }
        }
      `}</style>
    </>
  );
}
