"use client";
import { useState, useEffect } from "react";
import Script from "next/script";

const KlaviyoSignup = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkDeviceType = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkDeviceType);

    checkDeviceType();

    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return (
    <>
      <Script
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=TKpEPu"
        strategy="lazyOnload"
      />

      {isMobile ? (
        <div id="mobile-klaviyo-signup">
        <div className="klaviyo-form-UXbuUr"></div>
        </div>
      ) : (
        <div id="desktop-klaviyo-signup">
        <div className="klaviyo-form-W6esSb"></div>
        </div>
      )}
    </>
  );
};

export default KlaviyoSignup;
